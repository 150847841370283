import React from "react";
import styles from "./styles.module.css";
import { useDrag } from "react-dnd";

const ApprovalStepCard = ({ roles }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "div",
    item: { ...roles },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div className={styles.aCard} ref={drag}>
      <div className={styles.team}>{roles?.name || roles?.roleName}</div>
    </div>
  );
};

export default ApprovalStepCard;
