export function getStoredUser() {
  const storedUser = localStorage.getItem("user");
  return storedUser ? JSON.parse(storedUser) : null;
}

export function setStoredUser(user) {
  localStorage.setItem("user", JSON.stringify(user.data));
}

export function setAdmin(data) {
  localStorage.setItem(
    "admin",
    JSON.stringify(
      data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
    )
  );
}

export function getAdmin() {
  const storedUser = localStorage.getItem("admin");
  return storedUser ? JSON.parse(storedUser) : null;
}
