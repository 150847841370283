import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { getStoredUser, setAdmin } from "../storage";
const SERVER_ERROR = "There was an error contacting the server.";
export const getDecodedJWT = () => {
  try {
    const token = getStoredUser().jwtToken;
    const decoded = jwtDecode(token);
    setAdmin(decoded);
    return decoded;
  } catch (e) {
    return null;
  }
};

export const isAuthenticated = () => {
  try {
    const decode = getDecodedJWT();
    if (decode) {
      const { exp } = decode;
      const currentTime = Date.now() / 1000;

      return exp > currentTime;
    }
    localStorage.removeItem("user");
    return false;
  } catch (e) {
    localStorage.removeItem("user");
    return false;
  }
};

export const toastOptions = {
  position: toast.POSITION.BOTTOM_CENTER,
  autoClose: 8000,
  draggable: true,
  //   theme: "dark",
  timeOut: 2000,
  pauseOnHover: true,
};

export const successAlert = (msg) => {
  toast.success(msg || "Successfully created", {
    position: toast.POSITION.TOP_RIGHT,
  });
};
export const errorAlert = (error) => {
  const err = error?.response?.data?.responseMessage
    ? error?.response?.data?.responseMessage
    : SERVER_ERROR;
  toast.error(err, toastOptions);
};
