import React from "react";
import styles from "./styles.module.css";

const ProgressStep = ({ data = [], index, changeStep }) => {
  return (
    <div className={styles.container}>
      <div className={`${styles.arrowSteps} ${styles.clearfix}`}>
        {data?.map((item, i) => (
          <div
            className={`${styles.step} ${
              i + 1 === index ? styles.current : ""
            }`}
            key={i}
            onClick={() => changeStep(item.value)}
          >
            <span>
              <>{item?.title}</>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressStep;
