import React from "react";
import styles from "./styles.module.css";
import { useDrag } from "react-dnd";
import {
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaTimesCircle,
} from "react-icons/fa";

const ApprovalCard = ({
  formData,
  list,
  roles,
  onChange,
  moveUp,
  moveDown,
  remove,
  getNameByID = () => {},
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "div",
    item: { ...roles },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  const getItemName = formData?.approvalLists?.find(
    (x) => x.roleId === roles?.roleId
  );

  return (
    <div
      className={`${styles.aCard} ${
        isDragging ? styles.shadow : styles.noShadow
      }}`}
      ref={drag}
    >
      <div className={styles.controls}>
        <FaChevronCircleDown onClick={moveDown} />
        <FaChevronCircleUp onClick={moveUp} />
        <FaTimesCircle onClick={remove} />
      </div>
      <div className={styles.team}>
        {roles?.name || roles?.roleName || getNameByID(roles?.roleId)}
      </div>
      {list?.map((item, i) => (
        <div key={i}>
          <div className={styles.inputs}>
            <span>
              <input
                type="checkbox"
                name={item?.name}
                value={getItemName?.[item?.name]}
                checked={getItemName?.[item?.name]}
                onChange={(e) => onChange(e, roles.roleId)}
              />
              &nbsp;&nbsp;{item?.label}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ApprovalCard;
