import { useEffect } from "react";
import RoutesWrapper from "./routes";

function App() {
  useEffect(() => {
    // Function to prevent the context menu (right-click menu)
    const preventContextMenu = (e) => {
      e.preventDefault();
    };

    // Add the event listener when the component mounts
    document.addEventListener("contextmenu", preventContextMenu);

    // Remove the event listener when the component unmounts to avoid memory leaks
    return () => {
      document.removeEventListener("contextmenu", preventContextMenu);
    };
  }, []);

  return <RoutesWrapper />;
}

export default App;
