import React, { useState } from "react";
import logo from "../../assets/logo.png";
import styles from "./styles.module.css";
import { toast, ToastContainer } from "react-toastify";
import { errorAlert, successAlert, toastOptions } from "../../utils";
import { useLogin } from "./hooks";
import { useIsMutating } from "@tanstack/react-query";
import { InfinitySpin } from "react-loader-spinner";
import { useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useEffect } from "react";
import { FaWindows } from "react-icons/fa";
export const msalConfig = {
  auth: {
    clientId: "9fd74419-b276-444e-9695-be9fc1747fac",
    authority: `https://login.microsoftonline.com/55fbc70a-e57f-4db4-9a04-388a9338ca95`,
    redirectUri: "https://obladmin.nmfb.com.ng/.auth/login/aad/callback", // Update with your redirect URI
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

const Home = () => {
  const { instance, inProgress, accounts } = useMsal();

  const [loading, setLoading] = useState(false);

  const isLoading = useIsMutating();
  const { mutate, isSuccess, reset, isError, error } = useLogin();

  if (isSuccess) {
    reset();
    successAlert("Login Successful");
  }
  if (isError) {
    reset();
    errorAlert(error);
  }
  // const handleLogin = async () => {
  //   try {
  //     await instance.loginRedirect({
  //       interactionType: InteractionType.Redirect,
  //     });
  //   } catch (error) {
  //     toast.error(error, toastOptions);
  //     console.error("Error during login:", error);
  //   }
  // };

  function clearAllCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const cookieName = cookie.split("=")[0];
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  }
  // const getAccessToken = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await instance.acquireTokenSilent({
  //       account: accounts[0], // Use the first account (user) for simplicity
  //       scopes: ["User.Read"],
  //     });
  //     const accessToken = response.accessToken;
  //     clearAllCookies();
  //     sessionStorage.clear();
  //     localStorage.clear();
  //     mutate(accessToken);

  //     setLoading(false);
  //     return response.accessToken; // Returning the access token
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error getting access token:", error);
  //     throw error; // Re-throw the error to be caught by the calling function
  //   }
  // };

  // useEffect(() => {
  //   if (accounts?.length > 0) {
  //     getAccessToken();
  //   }
  // }, [accounts]);

  const handleLogin = async () => {
    try {
      setLoading(true);
      // Use loginPopup instead of loginRedirect
      const loginResponse = await instance.loginPopup({
        scopes: ["User.Read"],
      });

      // loginPopup returns the same response as acquireTokenSilent, so you can use it directly
      const accessToken = loginResponse.accessToken;

      // Clear cookies and storage
      clearAllCookies();
      sessionStorage.clear();
      localStorage.clear();

      // Mutate the access token
      mutate(accessToken);

      setLoading(false);
      console.log(accessToken, "accessToken");
      return accessToken;
    } catch (error) {
      setLoading(false);
      errorAlert(error);
      console.error("Error during login:", error);
    }
  };
  return (
    <div className={styles.home}>
      <div className={styles.logo}>
        <img src={logo} alt="NMFB Logo" />
      </div>
      <h1>NMFB OBL</h1>
      <p>Admin's Portal</p>
      {isLoading || loading ? (
        <InfinitySpin width="200" color="#fff" />
      ) : (
        <div className={styles.loginBtn}>
          <p>Click on the Button to sign in with your Microsoft Account</p>
          {/* <MicrosoftLogin
              clientId="f9df6cfc-2b4c-4654-88e1-c9acea243247"
              authCallback={authHandler}
              tenantId="39dab133-5d7b-4bec-8eca-7f89b173e27f"
            /> */}
          <div className="btnContainer">
            <button
              type="button"
              onClick={handleLogin}
              className="btn btnWhite"
            >
              <FaWindows /> &nbsp;Login with Microsoft
            </button>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Home;
