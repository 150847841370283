export const BasePaths = {
  MAIN: "/app",
  CONFIG: "/app/config",
  LOAN: "/app/loan",
  APPROVAL: "/app/approval",
};
export const PublicPaths = {
  HOME: "/",
  LOGIN: "/",
};

export const PrivatePaths = {
  DASHBOORD: "/dashboard",
  LOAN: "/loan",
  ROLES: "/roles",
  CONFIG: "/config",
  BRANCH: "/branch",
  ADMIN: "/admin",
  AUDIT: "/audit",
  GROUPS: "/groups",
  APPROVAL: "/approval",
};
