const Options = {
  sex: [
    {
      value: "Male",
    },
    {
      value: "Female",
    },
  ],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  EducationData: [
    { value: "Primary Education" },
    { value: "Secondary Education" },
    { value: "OND" },
    { value: "HND" },
    { value: "Bachelors " },
    { value: "Masters " },
    { value: "PHD " },
  ],
  zones: [
    { value: "North Central (NC)" },
    { value: "North East (NE)" },
    { value: "North West (NW)" },
    { value: "South West (SW)" },
    { value: "South East (SE)" },
    { value: "South (SS)" },
  ],

  "South (SS)": [
    { value: "Akwa Ibom" },
    { value: "Bayelsa" },
    { value: "Cross River" },
    { value: "Delta" },
    { value: "Edo" },
    { value: "Rivers" },
  ],

  "South East (SE)": [
    { value: "Abia" },
    { value: "Anambra" },
    { value: "Ebonyi" },
    { value: "Enugu" },
    { value: "Imo" },
  ],
  "South West (SW)": [
    { value: "Ekiti" },
    { value: "Lagos" },
    { value: "Ogun" },
    { value: "Ondo" },
    { value: "Osun" },
    { value: "Oyo" },
  ],
  "North West (NW)": [
    { value: "Jigawa" },
    { value: "Kaduna" },
    { value: "Kano" },
    { value: "Katsina" },
    { value: "Kebbi" },
    { value: "Sokoto" },
    { value: "Zamfara" },
  ],
  "North East (NE)": [
    { value: "Adamawa" },
    { value: "Bauchi" },
    { value: "Borno" },
    { value: "Gombe" },
    { value: "Taraba" },
    { value: "Yobe" },
  ],
  "North Central (NC)": [
    { value: "Benue" },
    { value: "Kogi" },
    { value: "Kwara" },
    { value: "Nasarawa" },
    { value: "Niger" },
    { value: "Plateau" },
    { value: "Federal Capital Territory (Abuja)" },
  ],

  Abia: [
    { value: "Aba North" },
    { value: "Aba South" },
    { value: "Isiala-Ngwa North" },
    { value: "Isiala-Ngwa South" },
    { value: "Ukwa West" },
    { value: "Ukwa East" },
    { value: "Obingwa" },
    { value: "Ikwuano" },
    { value: "Bende" },
    { value: "Arochukwu" },
    { value: "Ohafia" },
    { value: "Isiukwuato" },
    { value: "Umuahia" },
    { value: "Ugwunagbo" },
    { value: "Osisioma" },
    { value: "Umuahia South" },
    { value: "Umu-Nneochi" },
  ],
  Adamawa: [
    { value: "Fufore" },
    { value: "Ganye" },
    { value: "Gombi" },
    { value: "Guyuk" },
    { value: "Jada" },
    { value: "Hong" },
    { value: "Demsa" },
    { value: "Shelleng" },
    { value: "Maiha" },
    { value: "Madagali" },
    { value: "Michika" },
    { value: "Mubi" },
    { value: "Mayo-Belwa" },
    { value: "Song" },
    { value: "Numan" },
    { value: "Jimeta" },
    { value: "Augusto-Belwa" },
    { value: "Mubi-South" },
    { value: "Yola" },
    { value: "Toungo" },
    { value: "Girei" },
    { value: "Lamurde" },
  ],
  "Akwa Ibom": [
    { value: "Eket" },
    { value: "Atai Essien Udim" },
    { value: "Etinan" },
    { value: "Etim Ekpo" },
    { value: "Iko Abasi" },
    { value: "Ini" },
    { value: "Ikono" },
    { value: "Itu" },
    { value: "Mbo" },
    { value: "Mkpat Enin" },
    { value: "Nsit Ibom" },
    { value: "Nsit Ubium" },
    { value: "Okobo" },
    { value: "Onna" },
    { value: "Oron" },
    { value: "Oruk Anam" },
    { value: "Ukanafun" },
    { value: "Ikot Ekpene" },
    { value: "Uquo Ibeno" },
    { value: "Abak" },
    { value: "Uruan" },
    { value: "Ibeno" },
    { value: "Urue Offong/Oruko" },
    { value: "Ekpe" },
    { value: "Uyo" },
    { value: "Obot Akara" },
    { value: "Ibesikpo Asutan" },
    { value: "Ibiono Ibom" },
    { value: "Eastern Obolo" },
    { value: "Udung Uko" },
    { value: "Ika" },
  ],

  Anambra: [
    { value: "Anaocha" },
    { value: "Njikoka" },
    { value: "Nnewi North" },
    { value: "Nnewi South" },
    { value: "Onitsha North" },
    { value: "Onitsha South" },
    { value: "Anambra" },
    { value: "Oyi," },
    { value: "Ihiala," },
    { value: "Akwa North," },
    { value: "Akwa South," },
    { value: "Aguata," },
    { value: "Orumba North," },
    { value: "Orumba South," },
    { value: "Ogbaru," },
    { value: "Idemili," },
    { value: "Idemili South," },
    { value: "Ayamelum," },
    { value: "Ekwusigo," },
    { value: "Anambra West," },
    { value: "Dunukofia," },
  ],

  Bauchi: [
    { value: "Bauchi Bogoro" },
    { value: "Giade" },
    { value: "Darazo" },
    { value: "Dass" },
    { value: "Ningi" },
    { value: "Shira" },
    { value: "Gamawa" },
    { value: "Itas Gadau" },
    { value: "Warji" },
    { value: "Katagum" },
    { value: "Missau" },
    { value: "Hira" },
    { value: "Toro" },
    { value: "TafawaBalewa" },
    { value: "Jama’are" },
    { value: "Zaki" },
    { value: "Alkaleri" },
    { value: "Dambam" },
    { value: "Kirfi" },
    { value: "Ganjuwa" },
  ],
  Bayelsa: [
    { value: "Brass" },
    { value: "Ekeremor" },
    { value: "Ogbia" },
    { value: "Sagbama" },
    { value: "Southern Ijaw" },
    { value: "Kolokuma/Opokuma" },
    { value: "Nembe" },
    { value: "Yenagoa" },
  ],
  Benue: [
    { value: "Buruku" },
    { value: "Ador" },
    { value: "Gboko" },
    { value: "Agatu" },
    { value: "Apa" },
    { value: "Gwer East" },
    { value: "Gwer West" },
    { value: "Guma" },
    { value: "Konshisha" },
    { value: "Kwande" },
    { value: "Kastina-Ala" },
    { value: "Makurdii" },
    { value: "Logo" },
    { value: "Ogbadibo" },
    { value: "Obi" },
    { value: "Oju" },
    { value: "Ohimini" },
    { value: "Oturkpo" },
    { value: "Okpokwu" },
    { value: "Ukum" },
    { value: "Tarka" },
    { value: "Vandekya" },
  ],
  Borno: [
    { value: "Abadam" },
    { value: "Chibok" },
    { value: "Bayo" },
    { value: "Gubio" },
    { value: "Jere" },
    { value: "Nganzai" },
    { value: "Gwoza" },
    { value: "Shani" },
    { value: "Bama" },
    { value: "Konduga" },
    { value: "Damboa" },
    { value: "Kaga" },
    { value: "Magumeri" },
    { value: "Kukawa" },
    { value: "Asira/Uba" },
    { value: "Monguno" },
    { value: "Marte" },
    { value: "Ngala" },
    { value: "Dikwa" },
    { value: "Mafa" },
    { value: "Biu" },
    { value: "Maiduguri" },
    { value: "Kwanga-Kusar" },
    { value: "Guzamala" },
    { value: "Kale/ Balge" },
    { value: "Mobbar" },
    { value: "Hawul" },
  ],
  "Cross River": [
    { value: "Obubra" },
    { value: "Akpabuyo" },
    { value: "Yala" },
    { value: "Abi" },
    { value: "Odukpani" },
    { value: "Akamkpa" },
    { value: "Biase" },
    { value: "Ikom" },
    { value: "Yarkur" },
    { value: "Boki" },
    { value: "Ogoja" },
    { value: "Obanliku" },
    { value: "Bakassi" },
    { value: "Obudu" },
    { value: "Calabar South" },
    { value: "Etung" },
    { value: "Bekwara" },
    { value: "Calabar Municipal" },
  ],
  Delta: [
    { value: "Oshimili" },
    { value: "Aniocha" },
    { value: "Aniocha South" },
    { value: "Ika South" },
    { value: "Ika North-East" },
    { value: "Ndokwa West" },
    { value: "Ndokwa East" },
    { value: "Isoko South" },
    { value: "Isoko North" },
    { value: "Bomadi" },
    { value: "Burutu" },
    { value: "Ughelli South" },
    { value: "Ughelli North" },
    { value: "Ethiope West" },
    { value: "Ethiope East" },
    { value: "Sapele" },
    { value: "Okpe" },
    { value: "Warri North" },
    { value: "Warri South" },
    { value: "Uvwie" },
    { value: "Udu" },
    { value: "Warri Central" },
    { value: "Ukwani" },
    { value: "Oshimili North" },
    { value: "Patani" },
  ],
  Ebonyi: [
    { value: "Oniocha" },
    { value: "Afikpo South" },
    { value: "Ohaozara" },
    { value: "Afikpo North" },
    { value: "Izzi" },
    { value: "Abakaliki" },
    { value: "Ishielu" },
    { value: "Ohaukwu" },
    { value: "Ikwo" },
    { value: "Ezza South" },
    { value: "Ebonyi" },
    { value: "Ivo" },
    { value: "Ezza" },
  ],
  Edo: [
    { value: "Esan North-East" },
    { value: "Orhionwon" },
    { value: "Esan Central" },
    { value: "Esan West" },
    { value: "Egor" },
    { value: "Ikpoba-Okha" },
    { value: "Owan East" },
    { value: "Owan West" },
    { value: "Etsako Central" },
    { value: "Etsako West" },
    { value: "Igueben" },
    { value: "Oredo" },
    { value: "Ovia South-West" },
    { value: "Ovia South-East" },
    { value: "Uhunmwonde" },
    { value: "Etsako East" },
    { value: "Etsako South-East" },
    { value: "Akoko Edo" },
  ],
  Ekiti: [
    { value: "Ado" },
    { value: "Ekiti-East" },
    { value: "Ikere" },
    { value: "Ekiti-West" },
    { value: "Emure" },
    { value: "Irepodun" },
    { value: "Ekiti South-West" },
    { value: "Ijero" },
    { value: "Ido/Osi" },
    { value: "Oye" },
    { value: "Ikole" },
    { value: "Moba" },
    { value: "Aiyedire" },
    { value: "Efon" },
    { value: "Ise/Orun" },
    { value: "Ilejemeje" },
  ],
  Enugu: [
    { value: "Enugu South" },
    { value: "Aninri" },
    { value: "Udenu" },
    { value: "Nssuka" },
    { value: "Igbo-Eze South" },
    { value: "Enugu North" },
    { value: "Agwu" },
    { value: "Nkanu West" },
    { value: "Udi" },
    { value: "Oji-River" },
    { value: "Igbo-Ekiti,Ezeagu" },
    { value: "Igbo-Eze North" },
    { value: "Isi-Uzo" },
    { value: "Uzo-Uwani" },
    { value: "Enugu East" },
    { value: "Nkanu East" },
  ],
  Gombe: [
    { value: "Balanga" },
    { value: "Funakaye" },
    { value: "Shomgom" },
    { value: "Kaltungo" },
    { value: "Billiri" },
    { value: "Kwami" },
    { value: "Gombe" },
    { value: "Nafada/Bajoga" },
    { value: "Akko" },
    { value: "Yamaltu/Deba" },
    { value: "Dukku" },
  ],
  Imo: [
    { value: "Ahiazu-Mbaise" },
    { value: "Ezinihite" },
    { value: "Ideato South" },
    { value: "Ihitte/Uboma" },
    { value: "Mbaitoli" },
    { value: "Obowo" },
    { value: "Nkwerre" },
    { value: "Orsu" },
    { value: "Orlu" },
    { value: "Oguta" },
    { value: "Ngor Okpala" },
    { value: "Ohaji Egbema" },
    { value: "Okigwe" },
    { value: "Isiala-Mbano" },
    { value: "Owerri Municipal" },
    { value: "Oru" },
    { value: "Owerri West" },
    { value: "Owerri North" },
    { value: "Oru West" },
    { value: "Isu" },
    { value: "Onuimo" },
    { value: "Nwangele" },
    { value: "Njaba" },
    { value: "Ehime-Mbano" },
    { value: "Ideato North" },
    { value: "Aboh-Mbaise" },
    { value: "Ikeduru" },
  ],
  Jigawa: [
    { value: "Dutse" },
    { value: "Birni-Kudu" },
    { value: "Gwaram" },
    { value: "Hadejia" },
    { value: "Kiyawa" },
    { value: "Biriniwa" },
    { value: "Malam Madori" },
    { value: "Jahun" },
    { value: "Kafin Hausa" },
    { value: "Kazaure" },
    { value: "Roni" },
    { value: "Ringim" },
    { value: "Garki" },
    { value: "Babura" },
    { value: "Kaugama" },
    { value: "Sule-Tankarkar" },
    { value: "Taura" },
    { value: "Gwiwa" },
    { value: "Gumel" },
    { value: "Maigatari" },
    { value: "Kiwi Kasama" },
    { value: "Guri" },
    { value: "Gagarawa" },
    { value: "Auyo" },
    { value: "Yankwashi" },
    { value: "Buji" },
    { value: "Miga" },
  ],
  Kaduna: [
    { value: "Kaduna North" },
    { value: "Kajuru" },
    { value: "Kaduna South" },
    { value: "Kachia" },
    { value: "Jaba" },
    { value: "Zangon-Kataf" },
    { value: "Kaura" },
    { value: "Jema’a" },
    { value: "Lerea" },
    { value: "Ikara" },
    { value: "Makarfi" },
    { value: "Sabon-Gari" },
    { value: "Zaria" },
    { value: "Giwa" },
    { value: "Birnin-Gwari" },
    { value: "Igbabi" },
    { value: "Soba" },
    { value: "Chikun" },
    { value: "Kudan" },
    { value: "Kubau" },
    { value: "Sanga" },
    { value: "Kagarko" },
    { value: "Kauru" },
  ],
  Kano: [
    { value: "Rimin Gado" },
    { value: "Doguwa" },
    { value: "Tudun Wada" },
    { value: "Sumaila" },
    { value: "Wudil" },
    { value: "Takali" },
    { value: "Albasu" },
    { value: "Bebeji" },
    { value: "Rano" },
    { value: "Bunkure" },
    { value: "Karaye" },
    { value: "Kiru" },
    { value: "Kabo" },
    { value: "Kura" },
    { value: "Madobi" },
    { value: "Gwarzo" },
    { value: "Shanono" },
    { value: "Dawakin" },
    { value: "Makoda" },
    { value: "Kudu" },
    { value: "Tsanyawa" },
    { value: "Bichi" },
    { value: "Dawakin Tofa" },
    { value: "Dambatta" },
    { value: "Minjibir" },
    { value: "Ungogo" },
    { value: "Gezawa" },
    { value: "Gebasawa" },
    { value: "Bagwai" },
    { value: "Gaya Tofa" },
    { value: "Warawa" },
    { value: "Fagge" },
    { value: "Kano Municipal" },
    { value: "Nasarawa" },
    { value: "Gwale" },
    { value: "Tarauni" },
    { value: "Ajingi" },
    { value: "Garko" },
    { value: "Dala" },
    { value: "Garum Mallam" },
    { value: "Kumbotso" },
    { value: "Makoda" },
    { value: "Rogo" },
    { value: "Kunchi" },
    { value: "Kibiya" },
  ],
  Katsina: [
    { value: "Katsina" },
    { value: "Zango" },
    { value: "Daura" },
    { value: "Mai-Adua" },
    { value: "Mashi" },
    { value: "Kaita" },
    { value: "Ingawa" },
    { value: "Kankiya" },
    { value: "Dustin-Ma" },
    { value: "Matazu" },
    { value: "Safana" },
    { value: "Musawa" },
    { value: "Kankara" },
    { value: "Malumfashi" },
    { value: "Kafur" },
    { value: "Danja" },
    { value: "Funtua" },
    { value: "Bakori" },
    { value: "Faskari" },
    { value: "Sandamu" },
    { value: "Dandume" },
    { value: "Kusada" },
    { value: "Baure" },
    { value: "Dan-Musa" },
    { value: "Dutse" },
    { value: "Charanchi" },
    { value: "Sabuwa" },
    { value: "Batsari" },
    { value: "Jibiya" },
    { value: "Kurafi" },
    { value: "Rimi" },
    { value: "Batagarawa" },
    { value: "Mani" },
    { value: "Bindawa" },
  ],
  Kebbi: [
    { value: "Birnin-Kebbi" },
    { value: "Ngaski" },
    { value: "Yauri" },
    { value: "Sakaba" },
    { value: "Wasugu" },
    { value: "Danko" },
    { value: "Zuru" },
    { value: "Koko-Besse" },
    { value: "Bagudo" },
    { value: "Arewa Dandi" },
    { value: "Suru" },
    { value: "Maiyama" },
    { value: "Jega" },
    { value: "Bunza" },
    { value: "Gwandu" },
    { value: "Argungu" },
    { value: "Augie" },
    { value: "Fakai" },
    { value: "Aliero" },
    { value: "Kalgo" },
    { value: "Shanga" },
  ],
  Kogi: [
    { value: "Adavi" },
    { value: "Olamabolo" },
    { value: "Ajaokuta" },
    { value: "Ijumu" },
    { value: "Bassa" },
    { value: "Dekina" },
    { value: "Idaho" },
    { value: "Ankpa" },
    { value: "Yagba East" },
    { value: "Kogi" },
    { value: "Ofu" },
    { value: "Okehi" },
    { value: "Okene" },
    { value: "Yagba West" },
    { value: "Omala" },
    { value: "Igalamela-Odolu" },
    { value: "Ibaji" },
    { value: "Lokoja" },
    { value: "Ogori/Magongo" },
    { value: "Mopa-Muro" },
    { value: "Kabba/Bunu" },
  ],
  Kwara: [
    { value: "Asa" },
    { value: "Ifelodun" },
    { value: "Baruten" },
    { value: "Edu" },
    { value: "Ekiti" },
    { value: "Ilorin-East" },
    { value: "Ilorin West" },
    { value: "Kaiama" },
    { value: "Moro" },
    { value: "Offa" },
    { value: "Oyun" },
    { value: "Pategi" },
    { value: "Osin" },
    { value: "Oke-Ero" },
    { value: "Irepodun" },
    { value: "IlorinSouth" },
  ],
  Lagos: [
    { value: "Shomolu" },
    { value: "Agege" },
    { value: "Alimosho" },
    { value: "Lagos Island" },
    { value: "Lagos Mainland" },
    { value: "Mushin" },
    { value: "Oshodi-Isolo" },
    { value: "Surulere" },
    { value: "Ikorodu" },
    { value: "Eti-Osa" },
    { value: "Ibeju-Lekki" },
    { value: "Epe" },
    { value: "Ojo" },
    { value: "Badagry" },
    { value: "Ikeja" },
    { value: "Kosofe" },
    { value: "Amuwo Odofin" },
    { value: "Ajeromi/Ifelodun" },
    { value: "Ifako/Ijaiye" },
    { value: "Apapa" },
  ],
  Nasarawa: [
    { value: "Wamba" },
    { value: "Kokona" },
    { value: "Keana" },
    { value: "Nasarawa/Eggon" },
    { value: "Toto" },
    { value: "Awe" },
    { value: "Akwanga" },
    { value: "Keff" },
    { value: "Karu" },
    { value: "Lafia" },
    { value: "Obi" },
    { value: "Doma" },
    { value: "Nasarawa" },
  ],
  Niger: [
    { value: "Agaie" },
    { value: "Bosso" },
    { value: "Agwara Bida" },
    { value: "Lavun" },
    { value: "Borgu" },
    { value: "Chanchaga" },
    { value: "Edati" },
    { value: "Gurara" },
    { value: "Katcha" },
    { value: "Gbako" },
    { value: "Kontagora" },
    { value: "Lapai" },
    { value: "Magama" },
    { value: "Shiroro" },
    { value: "Mashegu" },
    { value: "Muya" },
    { value: "Mokwa" },
    { value: "Pailoro" },
    { value: "Mariga" },
    { value: "Rijau" },
    { value: "Suleja" },
    { value: "Wushishi" },
    { value: "Tafa" },
  ],
  Ogun: [
    { value: "Abeokuta North" },
    { value: "Abeokuta South" },
    { value: "Ogun Water-Side" },
    { value: "Ijebu-Ode" },
    { value: "Ijebu North" },
    { value: "Ijebu North East" },
    { value: "Ijebu East" },
    { value: "Odogbolu" },
    { value: "Ikenne" },
    { value: "Sagamu" },
    { value: "Odeda" },
    { value: "Iffo" },
    { value: "Obafemi Owode" },
    { value: "Egbado North" },
    { value: "Ado-Odo/Ota" },
    { value: "Imeko-Afon" },
    { value: "Egbado South" },
    { value: "Ipokia" },
    { value: "Ewekoro" },
    { value: "Idarapo" },
  ],
  Ondo: [
    { value: "Ondo" },
    { value: "Odigbo" },
    { value: "Okitipupa" },
    { value: "Ilaje" },
    { value: "Irele" },
    { value: "Akure South" },
    { value: "Akoko North East" },
    { value: "Akoko South West" },
    { value: "Idanre" },
    { value: "Ile-Oluji/Oke-Igbo" },
    { value: "Ose" },
    { value: "Ifedore" },
    { value: "Akoko North-West" },
    { value: "Akure North" },
    { value: "Owo" },
    { value: "Ondo East" },
    { value: "Ilaje West" },
    { value: "Akoko South East" },
  ],
  Osun: [
    { value: "Ayedade" },
    { value: "Aiyedire" },
    { value: "Atakunmosa West" },
    { value: "Atakunmosa East" },
    { value: "Boripe" },
    { value: "Boluwaduro" },
    { value: "Ede South" },
    { value: "Ede North" },
    { value: "Egbedore" },
    { value: "Ejigbo" },
    { value: "Ifedayo" },
    { value: "Ife East" },
    { value: "Ife Central" },
    { value: "Ife North" },
    { value: "Ife South" },
    { value: "Ifelodun" },
    { value: "Irepodun" },
    { value: "Ila" },
    { value: "Irewole" },
    { value: "Ilesha East" },
    { value: "Isokan" },
    { value: "Ilesha West" },
    { value: "Iwo" },
    { value: "Obokun" },
    { value: "Ola-Oluwa" },
    { value: "Odo-Otin" },
    { value: "Olorunda" },
    { value: "Oriade" },
    { value: "Oshogbo" },
    { value: "Orolu" },
  ],
  Oyo: [
    { value: "Afijo" },
    { value: "Akinyele" },
    { value: "Egbeda" },
    { value: "Ibadan Central" },
    { value: "Ibadan North-East" },
    { value: "Ibadan South-West" },
    { value: "Ibadan South-East" },
    { value: "Ibarapa" },
    { value: "Ido" },
    { value: "Ifedapo" },
    { value: "Ifeloju" },
    { value: "Irepo" },
    { value: "Iseyin" },
    { value: "Kajola" },
    { value: "Lagelu" },
    { value: "Ogbomosho North" },
    { value: "Oyo West" },
    { value: "Ogbomosho South" },
    { value: "Atiba" },
    { value: "Saki East" },
    { value: "Atisbo" },
    { value: "Saki West" },
    { value: "Itesiwaju" },
    { value: "Ibarapa North" },
    { value: "Iwajowa" },
    { value: "Olorunsogo" },
    { value: "Surulere" },
    { value: "Oluloye" },
    { value: "Orelope" },
    { value: "Ogo-Oluwa" },
    { value: "Orire" },
    { value: "Oyo East" },
    { value: "Ona-Ara" },
  ],
  Plateau: [
    { value: "Jos North" },
    { value: "Bassa" },
    { value: "Jos South" },
    { value: "Barakin Ladi" },
    { value: "Bokkos" },
    { value: "Mangu" },
    { value: "Pankshin" },
    { value: "Kanam" },
    { value: "Langtang North" },
    { value: "Wase" },
    { value: "Langtang South" },
    { value: "Qua’an pan" },
    { value: "Jos East" },
    { value: "Riyom" },
    { value: "Mikang" },
    { value: "Kanke" },
  ],
  Rivers: [
    { value: "Ogba/Egbema/Ndoni" },
    { value: "Ahoada East" },
    { value: "Ikwerre" },
    { value: "Etche" },
    { value: "Andoni/Opobo" },
    { value: "Bonny" },
    { value: "Okrika" },
    { value: "Oyigbo" },
    { value: "Khana" },
    { value: "Gokana Tai" },
    { value: "Eleme" },
    { value: "Obia/Akpor" },
    { value: "Emohua" },
    { value: "Degema" },
    { value: "Asari Toru" },
    { value: "Akuku-Toru" },
    { value: "Abua/Odual" },
    { value: "Omumma" },
    { value: "Opobo/Nkoro" },
    { value: "Ogu/Bolo" },
    { value: "Ahaoda West" },
    { value: "Eleme" },
    { value: "Port Harcourt" },
  ],
  Sokoto: [
    { value: "Tangaza" },
    { value: "Binji" },
    { value: "Illela" },
    { value: "Gada" },
    { value: "Sabon Birni" },
    { value: "Gawabawa" },
    { value: "Kware" },
    { value: "Goronyo" },
    { value: "Wurno" },
    { value: "Rabah" },
    { value: "Wamako" },
    { value: "Silame" },
    { value: "Isa" },
    { value: "Yabo" },
    { value: "Bodinga" },
    { value: "Tambulwal" },
    { value: "Dange-Shinsi" },
    { value: "Gudu" },
    { value: "Kebbe" },
    { value: "Sokoto North" },
    { value: "Shagari" },
    { value: "Sokoto South" },
    { value: "Tureta" },
  ],
  Taraba: [
    { value: "Jalingo" },
    { value: "Sardauna" },
    { value: "Takum" },
    { value: "Wukari" },
    { value: "Gashaka" },
    { value: "Bali" },
    { value: "Zing" },
    { value: "Yorro" },
    { value: "Donga" },
    { value: "Ibi" },
    { value: "Lau" },
    { value: "Ussa" },
    { value: "Ardo-Kola" },
    { value: "Gassol" },
    { value: "Kurmi" },
    { value: "KarinLamido" },
  ],
  Yobe: [
    { value: "Gujba" },
    { value: "Fika" },
    { value: "Nangere" },
    { value: "Giedam" },
    { value: "Yunusari" },
    { value: "Yusufari" },
    { value: "Jakusko" },
    { value: "Barde" },
    { value: "Bosari" },
    { value: "Nguru" },
    { value: "Machina" },
    { value: "Damaturu" },
    { value: "Potiskum" },
    { value: "Tarmua" },
    { value: "Karasuwa" },
    { value: "Gulani" },
  ],
  Zamfara: [
    { value: "Tsafe" },
    { value: "Zurmi" },
    { value: "Maradun" },
    { value: "Talata-Marafa" },
    { value: "Kaura-Namoda" },
    { value: "Bungudu" },
    { value: "Gussau" },
    { value: "Maru" },
    { value: "Anka" },
    { value: "Kiyawa" },
    { value: "Bukkuyum" },
    { value: "Bakura" },
    { value: "Gummi" },
    { value: "Shinkafi" },
  ],
  "Federal Capital Territory (Abuja)": [
    { value: "Gwagalada" },
    { value: "Kuje" },
    { value: "Abaji" },
    { value: "Abuja Municipal" },
    { value: "Bwari" },
    { value: "Kwali" },
  ],

  allstate: [
    { value: "Akwa Ibom" },
    { value: "Bayelsa" },
    { value: "Cross River" },
    { value: "Delta" },
    { value: "Edo" },
    { value: "Rivers" },
    { value: "Abia" },
    { value: "Anambra" },
    { value: "Ebonyi" },
    { value: "Enugu" },
    { value: "Imo" },
    { value: "Ekiti" },
    { value: "Lagos" },
    { value: "Ogun" },
    { value: "Ondo" },
    { value: "Osun" },
    { value: "Oyo" },
    { value: "Jigawa" },
    { value: "Kaduna" },
    { value: "Kano" },
    { value: "Katsina" },
    { value: "Kebbi" },
    { value: "Sokoto" },
    { value: "Zamfara" },
    { value: "Adamawa" },
    { value: "Bauchi" },
    { value: "Borno" },
    { value: "Gombe" },
    { value: "Taraba" },
    { value: "Yobe" },
    { value: "Benue" },
    { value: "Kogi" },
    { value: "Kwara" },
    { value: "Nasarawa" },
    { value: "Niger" },
    { value: "Plateau" },
    { value: "Federal Capital Territory (Abuja)" },
  ],

  banks: [
    { value: "Access Bank Plc" },
    { value: "Fidelity Bank Plc" },
    { value: "First City Monument Bank Limited" },
    { value: "First Bank of Nigeria Limited" },
    { value: "Guaranty Trust Holding Company Plc" },
    { value: "Union Bank of Nigeria Plc" },
    { value: "United Bank for Africa Plc" },
    { value: "Zenith Bank Plc" },
    { value: "Citibank Nigeria Limited" },
    { value: "Ecobank Nigeria" },
    { value: "Heritage Bank Plc" },
    { value: "Keystone Bank Limited" },
    { value: "Polaris Bank Limited" },
    { value: "Stanbic IBTC Bank Plc" },
    { value: "Standard Chartered" },
    { value: "Sterling Bank Plc" },
    { value: "Titan Trust Bank Limited" },
    { value: "Unity Bank Plc" },
    { value: "Wema Bank Plc" },
    { value: "Globus Bank Limited" },
    { value: "Parallex Bank Limited" },
    { value: "Providus Bank Limited" },
    { value: "SunTrust Bank Nigeria Limited" },
    { value: "Jaiz Bank Plc" },
    { value: "LOTUS BANK" },
    { value: "TAJBank Limited" },
    { value: "Nirsal Microfinance Bank" },
    { value: "Mutual Trust Microfinance Bank" },
    { value: "Rephidim Microfinance Bank" },
    { value: "Shepherd Trust Microfinance Bank" },
    { value: "Empire Trust Microfinance Bank" },
    { value: "Finca Microfinance Bank Limited" },
    { value: "Fina Trust Microfinance Bank" },
    { value: "Accion Microfinance Bank" },
    { value: "Peace Microfinance Bank" },
    { value: "Infinity Microfinance Bank" },
    { value: "Pearl Microfinance Bank Limited" },
    { value: "Covenant Mirofinance Bank Ltd" },
    { value: "Sparkle Bank" },
    { value: "Kuda Bank" },
    { value: "Rubies Bank" },
    { value: "VFD MFB" },
    { value: "Mint Finex MFB" },
    { value: "Mkobo MFB" },
    { value: "Coronation Merchant Bank" },
    { value: "FBNQuest Merchant Bank" },
    { value: "FSDH Merchant Bank" },
    { value: "Rand Merchant Bank" },
    { value: "Nova Merchant Bank" },
  ],

  loanType: [
    { value: "SME" },
    { value: "Petty Cash Now Now" },
    { value: "SALAD" },
  ],
  docType: [{ value: "CAC" }],
  loanTenor: [
    { value: 3, name: "3 Months" },
    { value: 6, name: "6 Months" },
    { value: 12, name: "12 Months" },
    { value: 36, name: "36 Months" },
  ],
  collateralType: [
    { value: "Movable Asset (Vehicle)", name: "Movable Asset (Vehicle)" },
    { value: "Landed Property", name: "Landed Property" },
    { value: "Stock Hypothecation", name: "Stock Hypothecation" },
    { value: "All Assets Debenture", name: "All Assets Debenture" },
    { value: "Other Movable Assets", name: "Other Movable Assets" },
    {
      value: "Movable Asset + Landed Property",
      name: "Movable Asset + Landed Property",
    },
    {
      value: "Movable Asset + Stock Hypothecation",
      name: "Movable Asset + Stock Hypothecation",
    },
    {
      value: "Landed Property + Stock Hypothecation",
      name: "Landed Property + Stock Hypothecation",
    },
    {
      value: "Others",
      name: "Others",
    },
  ],

  businessSector: [
    { value: "Agriculture", name: "Agriculture" },
    { value: "Animal Feeds", name: "Animal Feeds" },
    { value: "Aviation", name: "Aviation" },
    { value: "Wholesale/Retail Sales", name: "Wholesale/Retail Sales" },
    { value: "Consulting Services", name: "Consulting Services" },
    { value: "Construction", name: "Construction" },
    { value: "Education and Training", name: "Education and Training" },
    {
      value: "Energy and Power Generation",
      name: "Energy and Power Generation",
    },
    { value: "Fast Moving Consumer Goods", name: "Fast Moving Consumer Goods" },
    { value: "Fashion", name: "Fashion" },
    { value: "Financial Services", name: "Financial Services" },
    { value: "Haulage & Logistics", name: "Haulage & Logistics" },
    { value: "Healthcare", name: "Healthcare" },
    { value: "ICT Sales and Repairs", name: "ICT Sales and Repairs" },
    { value: "Manufacturing", name: "Manufacturing" },
    { value: "Media & Entertainment", name: "Media & Entertainment" },
    { value: "Oil & Gas", name: "Oil & Gas" },
    { value: "Pharmaceutical Products", name: "Pharmaceutical Products" },
    { value: "Professional Services", name: "Professional Services" },
    { value: "Real Estate", name: "Real Estate" },
    { value: "Sale of household Items", name: "Sale of household Items" },
    { value: "Telecommunications", name: "Telecommunications" },
    { value: "Tourism/Hospitality", name: "Tourism/Hospitality" },
    { value: "Transportation", name: "Transportation" },
    { value: "Waste Management", name: "Waste Management" },
    { value: "Others", name: "Others" },
  ],
  month: [
    { value: "January" },
    { value: "February" },
    { value: "March" },
    { value: "April" },
    { value: "May" },
    { value: "June" },
    { value: "July" },
    { value: "August" },
    { value: "September" },
    { value: "October" },
    { value: "November" },
    { value: "December" },
  ],
  loanStatus: [
    { value: "Approved", title: "Approve" },
    { value: "Declined", title: "Decline" },
  ],
};
export default Options;
