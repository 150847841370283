import { createContext, useEffect, useState } from "react";
import { isAuthenticated } from "../utils";
import { getStoredUser } from "../storage";

export const AuthContext = createContext({
  user: undefined,
  token: "",
  isAuthenticated: false,
  authenticate: (token) => {},
  logout: () => {},
  approvalRole: [],
  setApprovalRole: () => {},
});

function AuthContextProvider({ children }) {
  const [authToken, setAuthToken] = useState(null);
  const [approvalRole, setApprovalRole] = useState([]);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    if (!isAuthenticated()) {
      logout();
    }
  }, []);

  useEffect(() => {
    const data = getStoredUser();
    if (data) {
      setUser(data);
      setAuthToken(data.jwtToken);
    }
  }, []);

  function authenticate(token) {
    setAuthToken(token.data.jwtToken);
    setUser(token.data);
  }
  function logout() {
    setUser(undefined);
    setAuthToken(null);
    localStorage.removeItem("user");
    // window.location.reload();
  }

  function addRoles(roles) {
    setApprovalRole(roles);
  }

  const value = {
    user: user,
    token: authToken,
    isAuthenticated: !!authToken,
    authenticate: authenticate,
    logout: logout,
    approvalRole: approvalRole,
    setApprovalRole: setApprovalRole,
    addRoles: addRoles,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
